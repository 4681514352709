import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    asset: null
  },
  mutations: {
    setAsset(state, asset) {
      state.asset = asset
    }
  },
  actions: {
  },
  modules: {
  }
})
