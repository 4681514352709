import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'

Vue.config.productionTip = false

// filtre
Vue.filter('time2date', function (value: number) {

  if (!value) {
    return "invalide date";
  }

  return moment.unix(value).format("DD/MM/YY HH:mm:ss");

})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
