
import Plotly from "plotly.js-dist";

export interface OhlcvInterface {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
}

export class PlotlyMaker {

    layout = {
        dragmode: "zoom",
        margin: {
            r: 10,
            t: 25,
            b: 40,
            l: 60,
        },
        showlegend: false,
        xaxis: {
            autorange: true,
            domain: [0, 1],
            range: ["2017-01-03 12:00", "2017-02-15 12:00"],
            rangeslider: { range: ["2017-01-03 12:00", "2017-02-15 12:00"] },
            title: "Date",
            type: "date",
        },
        yaxis: {
            autorange: true,
            domain: [0, 1],
            range: [114.609999778, 137.410004222],
            type: "linear",
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)'
    }


    // création
    public make(elementId: string, traceList: Array<any>) {
        Plotly.newPlot(elementId, traceList, this.layout);
    }




    // création de trace depuis OHLCV array
    public static makeOhlcvTrace(buildList: Array<OhlcvInterface>) {

        const trace = {
            x: [],
            open: [],
            high: [],
            low: [],
            close: [],
            // decreasing: { line: { color: "#109E2F" } },
            // increasing: { line: { color: "#EB8100" } },

            type: "candlestick",
            xaxis: "x",
            yaxis: "y"
        }
        // rempli
        for (const ohlcv of buildList) {

            //const ohlcv = build.ohlcv

            trace.x.push(new Date(ohlcv.time * 1000))

            trace.open.push(ohlcv.open)

            trace.high.push(ohlcv.high)

            trace.low.push(ohlcv.low)

            trace.close.push(ohlcv.close)
        }


        //
        return trace
    }

    // un seul point
    public static makeSinglePoint(time, value, side) {

        let marker
        switch (side) {
            case 'buy':
                marker = {

                    color: 'white',
                    symbol: 'triangle-up',
                    size: 20,
                    line: {
                        color: 'rgb(2, 197, 8)',
                        width: 3
                    }
                }
                break
            case 'sell':
                marker = {
                    color: 'white',
                    symbol: 'triangle-down',
                    size: 20,
                    line: {
                        color: 'rgb(238, 81, 8)',
                        width: 3
                    }
                }
                break
            default:
                marker = {
                    color: 'white',
                    size: 20,
                    line: {
                        color: 'rgb(17, 157, 255)',
                        width: 3
                    }
                }
        }

        const trace = {
            x: [new Date(time * 1000)],
            y: [value],
            mode: 'markers',
            type: 'scatter',
            marker: marker
        }

        //
        return trace
    }

    public static makeBuildTraceList(buildList: Array<any>) {
        const indicatorTraceList: Array<any> = []
        const x = []

        // extrai les valeurs des indicateurs
        const result: any = {}

        for (const build of buildList) {
            x.push(build.ohlcv.time)
            for (const [key, value] of Object.entries(build.indicatorMap)) {
                if (!result[key]) {
                    result[key] = []
                }
                result[key].push(value)
            }
        }

        // cré les traces
        let trace: any = {
            x: x,
            y: [],
            mode: 'lines',
            type: 'scatter'
        }


        // cré les traces
        for (const [key, list] of Object.entries(result)) {


            trace = {
                x: x,
                y: list,
                mode: 'lines',
                type: 'scatter'
            }
            indicatorTraceList.push(trace)

        }



        return indicatorTraceList
    }

    // création de la trace depuis la liste des trades
    public static makeTradeTrace(tradeList: Array<any>) {


        const traceBuy = {
            x: [],
            y: [],
            mode: 'markers',
            type: 'scatter',
            marker: {
                color: 'blue',
                symbol: 'triangle-up',
                size: 20,
            },
            name: 'Buy'

        }

        const traceSell = {
            x: [],
            y: [],
            mode: 'markers',
            type: 'scatter',
            marker: {
                color: 'red',
                symbol: 'triangle-down',
                size: 20,
            },
            name: 'Sell'
        }

        // rempli
        for (const trade of tradeList) {
            if (trade.action == 'buy') {

                traceBuy.x.push(trade.ohlcv.time)

                traceBuy.y.push(trade.ohlcv.close)
            } else if (trade.action == 'sell') {

                traceSell.x.push(trade.ohlcv.time)

                traceSell.y.push(trade.ohlcv.close)
            }

        }
        //
        return {
            traceBuy: traceBuy,
            traceSell: traceSell
        }

    }
}
