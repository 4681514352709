















import Vue from "vue";

import AssetSearch from "./components/AssetSearch.vue";

export default Vue.extend({
  name: "App",
  components: { AssetSearch },
});
