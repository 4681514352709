






import Vue from "vue";
import AssetDetail from "../components/AssetDetail.vue";

export default Vue.extend({
  name: "Home",

  components: {
    AssetDetail,
  },
});
