// chargement de la configuration en fonction de la variable d'environnement
const getConfig = function () {
  if (process.env.NODE_ENV === 'development') {
    return require('../config.dev.json')
  } else {
    return require('../config.prod.json')
  }
}

// module
export default getConfig()
