










































import Vue from "vue";
import config from "../config";
import delay from "delay";

export default Vue.extend({
  name: "AssetTable",

  data: () => ({}),
  props: ["synthese"],
  computed: {
    asset() {
      return this.$store.state.asset;
    },
    syntheseFormated() {
      return [...this.synthese].sort((item: any, item2: any) => {
        if (item.time < item2.time) {
          return -1;
        }
        if (item.time > item2.time) {
          return 1;
        }
        return 0;
      });
    },
    typeList() {
      const typeList = new Map();
      for (let item of this.synthese) {
        if (!typeList.has(item.type)) {
          typeList.set(item.type, true);
        }
      }
      return Array.from(typeList.keys());
    },
    headers() {
      const headers = [];
      let c = 0;
      headers.push({ text: "Date", value: "time", id: c });
      c++;
      headers.push({
        text: "TimeFrame",
        value: "timeFrame",
        id: c,
        sortable: false,
      });
      for (let type of this.typeList) {
        c++;
        headers.push({ text: type, value: "type", id: c, sortable: false });
      }
      return headers;
    },
  },

  methods: {
    itemToClass(item) {
      return item.side;
    },
    prepare() {
      // todo
    },
  },
});
