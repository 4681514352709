






































import Vue from "vue";
import config from "../config";
import delay from "delay";

export default Vue.extend({
  name: "TimeUnitTable",

  data: () => ({}),
  props: ["synthese"],
  computed: {
    asset() {
      return this.$store.state.asset;
    },
    syntheseFormated() {
      return [...this.synthese].sort((item: any, item2: any) => {
        if (item.time < item2.time) {
          return -1;
        }
        if (item.time > item2.time) {
          return 1;
        }
        return 0;
      });
    },
    typeList() {
      const typeList = new Map();
      for (let item of this.synthese) {
        if (!typeList.has(item.type)) {
          typeList.set(item.type, true);
        }
      }
      return Array.from(typeList.keys());
    },
    headers() {
      const headers = [];
      let c = 0;
      headers.push({ text: "Date", value: "time", id: c });
      c++;
      headers.push({
        text: "W1",
        value: "1w",
        id: c,
        sortable: false,
      });
      c++;
      headers.push({
        text: "D1",
        value: "1d",
        id: c,
        sortable: false,
      });
      c++;
      headers.push({
        text: "H4",
        value: "4h",
        id: c,
        sortable: false,
      });
      return headers;
    },
  },

  methods: {
    itemToClass(item) {
      return item.side;
    },
    prepare() {
      // todo
    },
  },
});
