

















































































import Vue from "vue";
import axios from "axios";
import config from "../config";
import { OhlcvInterface, PlotlyMaker } from "../helper/PlotlyMaker";
import delay from "delay";
import AssetTable from "../components/AssetTable.vue";
import TimeUnitTable from "../components/TimeUnitTable.vue";

export default Vue.extend({
  name: "AssetDetail",
  components: {
    AssetTable,
    TimeUnitTable,
  },
  data: () => ({
    search: "",
    expanded: [],
    loading: false,
    headers: [
      { text: "Date", value: "time" },
      {
        text: "TimeFrame",
        value: "timeFrame",
      },
      { text: "Type", value: "type" },
      { text: "Side", value: "side" },
      { text: "Price", value: "price" },
      { text: "Misc", value: "misc", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    w1: [],
    d1: [],
    h4: [],
    synthese: [],
  }),
  computed: {
    asset() {
      return this.$store.state.asset;
    },
    ticker() {
      if (!this.asset) {
        return null;
      }
      const ticker = this.asset.exchange + ":" + this.asset.symbol;
      return ticker;
    },
    fields() {
      if (!this.asset) return [];

      return Object.keys(this.asset).map((key) => {
        return {
          key,
          value: this.asset[key] || "n/a",
        };
      });
    },
  },

  methods: {
    sideToColor(side: string) {
      switch (side) {
        case "buy":
          return "green";
        case "sell":
          return "red";
        default:
          return "blue";
      }
    },

    timeFrame2dataTimeFrame(timeFrame: string) {
      switch (timeFrame) {
        case "4h":
          return "h4";
        case "1d":
          return "d1";
        case "1w":
          return "w1";
        default:
          throw new Error("unknown time frame");
      }
    },

    async itemExpanded(data: any, value: boolean) {
      // TODO : enlever ce hack
      await delay(100);

      this.makeGraph(data.item);
    },

    cutAround(ohlcvList, timeCenter, number = 50) {
      // numéro de la bougie central
      let c = 0;
      for (let ohlcv of ohlcvList) {
        if (ohlcv.time == timeCenter) {
          break;
        }
        c++;
      }

      let start = c - number;

      if (c - number <= 0) {
        start = 0;
      }

      let end = c + number;

      //
      return ohlcvList.slice(start, end);
    },

    async makeGraph(item) {
      const dataTimeFrame = this.timeFrame2dataTimeFrame(item.timeFrame);

      let plotlyMaker = new PlotlyMaker();
      const ohlcvList = this.cutAround(
        this[dataTimeFrame].ohlcvList,
        item.time
      );

      const traceOhlcv = PlotlyMaker.makeOhlcvTrace(ohlcvList);

      const markerTrace = PlotlyMaker.makeSinglePoint(
        item.time,
        item.price,
        item.side
      );
      let traceList = [
        traceOhlcv,
        markerTrace,
        //  tradeTraceList.traceSell,
        // tradeTraceList.traceBuy,
      ];
      plotlyMaker.make("plotyContainer", traceList); // traceList.concat(indicatorTraceList)
    },

    async process() {
      await this.loadTimeFrame("1w", "w1");
      await this.loadTimeFrame("1d", "d1");
      await this.loadTimeFrame("4h", "h4");
    },

    async loadTimeFrame(timeFrame: string, saveName) {
      const res = await axios.get(
        config.server_site + "/ichimoku/" + this.ticker + "/" + timeFrame
      );

      this[saveName] = res.data.payload;
    },

    async makeSynthese() {
      await this.makeTimeFrameSynthese("w1");
      await this.makeTimeFrameSynthese("d1");
      await this.makeTimeFrameSynthese("h4");
    },

    async makeTimeFrameSynthese(timeFrame) {
      if (this[timeFrame] && this[timeFrame].signalList) {
        for (let data of this[timeFrame].signalList) {
          this.synthese.push(data);
        }
      }
    },
  },
  watch: {
    async ticker() {
      this.synthese = [];

      this.loading = true;

      await this.process();
      await this.makeSynthese();
      this.loading = false;
    },
  },
});
