









































import Vue from "vue";
import config from "../config";
export default Vue.extend({
  name: "AssetSearch",

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries;
      /*
      
      return this.entries.map((entry) => {
        let text =
          entry.description.length > this.descriptionLimit
            ? entry.description.slice(0, this.descriptionLimit) + "..."
            : entry.description;

        text += " - " + entry.type + " (" + entry.exchange + ")";

        return Object.assign({}, entry, { text });
      });
      */
    },
  },
  methods: {
    input() {
      this.$store.commit("setAsset", this.model);
    },
  },
  watch: {
    search(val) {
      // Items have already been loaded
      //     if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch(config.server_site + "/tradingview/symbol-search/" + val)
        .then((res) => res.json())
        .then((res) => {
          this.count = res.payload.length;
          this.entries = res.payload;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
});
